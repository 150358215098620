/* styles.css or any appropriate CSS file */

/* Default light mode styles (you can skip if already defined) */
body {
    background-color: white;
    color: black;
  }
  
  /* Dark mode styles */
  body.dark-mode {
    background-color: #121212;
    color: white;
  }
  
  .navbar-dark-mode {
    background-color: #333;
    color: white;
  }
  
  /* Example styles for the button */
  button {
    color: #fff;
    background-color: #6c757d;
  }
  button.dark-mode {
    background-color: #495057;
  }

  /* Optional: Custom Navbar CSS */
.navbar-dark .navbar-nav .nav-link {
    color: white;  /* Ensures nav links are white in dark mode */
}
  
.navbar-light .navbar-nav .nav-link {
    color: black;  /* Ensures nav links are black in light mode */
}

/* Global Dark Mode Styles */
body.dark-mode {
    background-color: #121212;
    color: white;
  }

/* Dark mode styles for textarea */
body.dark-mode textarea {
  background-color: #1b3a57;  /* Dark blue background */
  color: white;               /* White text */
  border: 1px solid #fff;     /* Dark border for textarea */
}

body.dark-mode textarea::placeholder {
  color: white;
}

/* Ensure the dark mode button is visible even in mobile */
button.ms-auto {
    display: flex;
    align-items: center;
    justify-content: center;
  }

/* Optional: Customize the button's size for smaller screens */
@media (max-width: 768px) {
    button {
      font-size: 1.2rem;
      padding: 8px 12px;
    }
  }


  /* styles.css */
.button-container {
    display: flex;
    flex-wrap: wrap;           /* Allows buttons to wrap if space is insufficient */
    justify-content: flex-start; /* Align buttons to the left */
    gap: 8px;                  /* Small space between buttons */
    margin-top: 20px;
}

.button-container .btn {
    font-size: 14px;           /* Smaller font size */
    padding: 8px 12px;         /* Smaller padding */
    margin: 0;                 /* Remove extra margins */
    min-width: 100px;          /* Minimum width for consistency */
    height: 40px;              /* Fixed height */
    border-radius: 4px;        /* Rounded corners */
}

/* Optional: Buttons with icons can still have the same size */
.button-container .btn i {
    font-size: 18px;           /* Icon size */
}


/* darkMode.css */
body.dark-mode {
  background-color: #212529;
  color: #f8f9fa;
  transition: all 0.3s ease;
}

/* Containers */
.container-dark {
  background-color: #2c3036;
  border: 1px solid #3a3f45;
  color: #f8f9fa;
}

/* Card Styles */
.card-dark {
  background-color: #2c3036;
  color: #f8f9fa;
  border: 1px solid #3a3f45;
}

/* Text Styles */
.text-muted-dark {
  color: #ced4da;
}
